<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <div class="test_result_box">
            <div class="test_result_con">

                <!-- 操作栏 -->
                <div class="clear"></div>
                <div class="operate_box">
                    <div class="btn_box">
                        <div class="btn" @click.prevent="resultdownloadbtn()">报告下载</div>
                        <div class="btn" @click.prevent="resultprintbtn()">在线打印</div>
                        <div class="clear"></div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="user_result_all">

                    <!-- 测评结果（转换PDF打印的容器） -->
                    <div class="user_result_box" ref="user_result_pdf_box">
                        <!-- 封面 -->
                        <div class="user_result_img_box">
                            <div class="radius1"></div>
                            <div class="user_box">
                                <div class="clear"></div>
                                <div class="info" style="margin-top:60px;">
                                    <span class="t">姓名：</span>
                                    <span class="x">小明同学</span>
                                </div>
                                <div class="info">
                                    <span class="t">电话：</span>
                                    <span class="x">16855458547</span>
                                </div>
                                <div class="info">
                                    <span class="t">测评时间：</span>
                                    <span class="x">2024年7月18日</span>
                                </div>
                                <div class="info">
                                    <span class="t">用时：</span>
                                    <span class="x">1小时20分</span>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="title_box">霍兰德测评报告</div>
                            <div class="mark_box">
                                <span>本测试结果以霍兰德职业测试为模型，结合新高考的选科特点和志愿填报数据，按照《中华人民共和国职业分类大典》进行职业分类。</span>
                                <span>查看你得分最高的三项的描述。哪些是对你的职业价值观比较适合的工作？你应该有意识的往这些方向选择自己的职业。</span>
                                <span>请看看得分最低的一个分项，那是你最不喜欢和擅长的一些工作，你应该有意识的避免它们。</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <!-- 一、兴趣维度 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_result">
                                    <div class="tit_t">测评结果</div>
                                    <div class="score_box">
                                        <div class="s_tit" style="float:left;">得分：</div>
                                        <div class="score">
                                            <div class="s" :class="'s_'+item.order" v-for="item in testResultTableData" :key="item.order">
                                                <span class="z">{{item.order}}</span>
                                                <span class="t">（{{item.type}}）</span>
                                                <span class="n">{{item.score}}<span class="f">分</span></span>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                        <div class="s_mark">
                                            分数越高（大于等于7分），说明性格特征越明显，对职业的影响就越大，测试可参考性就越大；分数低（小于等于3分）,说明此性格对职业选择基本没有影响。如果所有分数都低于6分，请直接忽视本次测试。
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="df_box">
                                            <div class="xqlx"><span class="tt">您的兴趣类型：</span><span class="jl_e">R I A </span><span class="jl_h">（现实型、研究型、艺术型）</span></div>
                                            <div class="jlmark">
                                                你在管理系统和使事物正常运行方面有出色的才能。传统稳定的、已经建立多年的团体让你心向往之。你的生活富有效率，认真而详尽地记录各种花销，不允许有任何未经检查或未经修正的错误或遗漏出现。建立起一整套标准的清单或规则让自己去行动会让你觉得很有安全感。你在独立、深入思考，发展自己发散性思维的方面需要加强。
                                            </div>
                                        </div>
                                        <div class="ld_box">
                                            <div class="clear"></div>
                                            <span class="xqld">兴趣雷达</span>
                                            <div class="cbox" id="ResultChartBoxXQ"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="clear"></div>
                                        <div class="type_table">
                                            <div class="list">
                                                <div class="head">
                                                    <div class="ttbox" style="background:#3db7c2;">
                                                        <div class="e">R</div>
                                                        <div class="z">（现实型）</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="t"><span>共同特征：</span></span><span class="x">1、喜欢常规的,有规则的活动,喜欢按照预先安排好的程序做事。<br/>2、尊重权威和规则,细心、有条理,关注细节。<br/>3、习惯接受他人的指挥和领导,不喜欢竞争。<br/>4、较为谨慎和保守,缺乏创造性,很少冒险,富有自我牺牲精神。</span></div>
                                                    <div class="tz"><span class="t"><span>性格特点：</span></span><span class="x">有责任心、依赖性强、高效率、保守、细致、有耐心</span></div>
                                                    <div class="tz"><span class="t"><span>别人评价：</span></span><span class="x">谨慎的,顺从的</span></div>
                                                    <div class="tz"><span class="t"><span>喜欢活动：</span></span><span class="x">1、有详尽计划,可掌控的活动<br/>2、追求精确和细节的活动</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="list">
                                                <div class="head">
                                                    <div class="ttbox" style="background:#6798e0;">
                                                        <div class="e">I</div>
                                                        <div class="z">（研究型）</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="t"><span>共同特征：</span></span><span class="x">1、喜欢常规的,有规则的活动,喜欢按照预先安排好的程序做事。<br/>2、尊重权威和规则,细心、有条理,关注细节。<br/>3、习惯接受他人的指挥和领导,不喜欢竞争。<br/>4、较为谨慎和保守,缺乏创造性,很少冒险,富有自我牺牲精神。</span></div>
                                                    <div class="tz"><span class="t"><span>性格特点：</span></span><span class="x">有责任心、依赖性强、高效率、保守、细致、有耐心</span></div>
                                                    <div class="tz"><span class="t"><span>别人评价：</span></span><span class="x">谨慎的,顺从的</span></div>
                                                    <div class="tz"><span class="t"><span>喜欢活动：</span></span><span class="x">1、有详尽计划,可掌控的活动<br/>2、追求精确和细节的活动</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="list">
                                                <div class="head">
                                                    <div class="ttbox" style="background:#f07a62;">
                                                        <div class="e">A</div>
                                                        <div class="z">（艺术型）</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="t"><span>共同特征：</span></span><span class="x">1、喜欢常规的,有规则的活动,喜欢按照预先安排好的程序做事。<br/>2、尊重权威和规则,细心、有条理,关注细节。<br/>3、习惯接受他人的指挥和领导,不喜欢竞争。<br/>4、较为谨慎和保守,缺乏创造性,很少冒险,富有自我牺牲精神。</span></div>
                                                    <div class="tz"><span class="t"><span>性格特点：</span></span><span class="x">有责任心、依赖性强、高效率、保守、细致、有耐心</span></div>
                                                    <div class="tz"><span class="t"><span>别人评价：</span></span><span class="x">谨慎的,顺从的</span></div>
                                                    <div class="tz"><span class="t"><span>喜欢活动：</span></span><span class="x">1、有详尽计划,可掌控的活动<br/>2、追求精确和细节的活动</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐专业：</div>
                                        <div class="tj_type">
                                            <div class="tj_tit"><span class="tj_ico"></span>本科专业</div>
                                            <div class="tj_box">
                                                <span class="b">财务管理</span>
                                                <span class="b">档案学</span>
                                                <span class="b">会计学</span>
                                                <span class="b">图书馆学</span>
                                                <span class="b">财务管理</span>
                                                <span class="b">档案学</span>
                                                <span class="b">会计学</span>
                                                <span class="b">图书馆学</span>
                                                <span class="b">财务管理</span>
                                                <span class="b">档案学</span>
                                                <span class="b">会计学</span>
                                                <span class="b">图书馆学</span>
                                                <span class="b">财务管理</span>
                                                <span class="b">档案学</span>
                                                <span class="b">会计学</span>
                                                <span class="b">图书馆学</span>
                                            </div>
                                        </div>
                                        <div class="tj_type">
                                            <div class="tj_tit"><span class="tj_ico"></span>专科专业</div>
                                            <div class="tj_box">
                                                <span class="b">财务管理</span>
                                                <span class="b">档案学</span>
                                                <span class="b">会计学</span>
                                                <span class="b">图书馆学</span>
                                                <span class="b">财务管理</span>
                                                <span class="b">档案学</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐职业：</div>
                                        <div class="tj_type">
                                            <div class="tj_box">
                                                <span class="b">安全员</span>
                                                <span class="b">雕刻师</span>
                                                <span class="b">房屋建筑施工人员</span>
                                                <span class="b">保安员</span>
                                                <span class="b">安检员</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>

        <!-- 加载效果 -->
        <div id="result_download_loading" style="display:none;width:100%;height:100%;position:fixed;left:0px;top:0px;z-index:888;background-color:rgba(0,0,0,0.2);">
            <div style="width:200px;height:80px;position:absolute;top:calc(50% - 40px);left:calc(50% - 100px);text-align:center;">
                <i class="el-icon-loading" style="font-size:40px;color:#fff;"></i>
                <span style="display:block;font-size:20px;color:#777;margin-top:10px;">报告生成中…</span>
            </div>
        </div>

    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import {downloadPDF} from "@/utils/pdf.js";  //转换PDF
import html2canvas from "html2canvas";
import { setStore,getStore } from '@/utils/utils';
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            hollandMainData: {},
            typeTestList: [],
            testResultTableData: [
                {
                type: "现实型",
                score: 0,
                percentage: 0,
                order: "R",
                name: "R：现实型",
                max: 10,
                content:
                    "(Realistic)又译技能型。具有这类倾向的个体，属于技术与运动取向。往往身体技能及机械协调能力较强，对机械与物体的关心比较强烈。稳健、务实，喜欢从事规则明确的活动及技术性工作，甚至热衷于亲自动手创造新事物。不善言谈，对于人际交往及人员管理、监督等活动不太感兴趣。",
                },
                {
                type: "研究型",
                score: 0,
                percentage: 0,
                order: "I",
                name: "I：研究型",
                max: 10,
                content:
                    "(Investigative)又译调查型。具有这类倾向的个体，喜欢理论思维或偏爱数理统计工作，对于解决抽象性问题具有极大的热情。他们通常倾向于通过思考、分析解决难题，而不一定落实到具体操作。喜欢具有创造性、挑战性的工作，不太喜欢固定程式的任务。对于人员的领导及人际交往也非情所愿，独立倾向明显。",
                },
                {
                type: "艺术型",
                score: 0,
                percentage: 0,
                order: "A",
                name: "A：艺术型",
                max: 10,
                content:
                    "(Artistic)具有此类倾向的个体，对具有创造、想象及自我表现空间的工作显示出明显偏好。他们和研究型倾向的个体相同之处在于创造倾向明显，对于结构化程度较高的任务及环境都不太喜欢，对于机械性及程式化的工作了无兴趣。也比较喜欢独立行事，不太合群。但两者所不同的是艺术倾向明显的个体好自我表现，重视自己的感性，直觉力较好，情绪变化较大。",
                },
                {
                type: "社会型",
                score: 0,
                percentage: 0,
                order: "S",
                name: "S：社会型",
                max: 10,
                content:
                    "(Social)具有此类倾向的个体，喜欢与人为对象的工作。他们通常言语能力优于数理能力，善于言谈，乐于与人相处，给人提供帮助，具有人道主义倾向，责任心也较强。习惯于与人商讨或调整人际关系来解决面临的问题。不太喜欢以机械和物品为对象的工作",
                },
                {
                type: "企业型",
                score: 0,
                percentage: 0,
                order: "E",
                name: "E：企业型",
                max: 10,
                content:
                    "(Enterprising)又译经营型。具有这种兴趣倾向的个体，喜欢制定新的工作计划、事业规划以及设立新的组织，并积极地发挥组织的作用进行活动；喜欢影响、管理、领导他人；自信、支配欲、冒险性强。他们不喜欢具体精细、或需要长时间集中心智的工作。",
                },
                {
                type: "常规型",
                score: 0,
                percentage: 0,
                order: "C",
                name: "C：常规型",
                max: 10,
                content:
                    "(conventional)又译事务型、传统型。具有这类倾向的个体，喜欢高度有序、要求明晰的工作，对于规则模糊、自由度大的工作不太适应。不喜欢主动决策，习惯于服从，一般较为忠诚、可靠，偏保守。与人工作中的交往会保持一定的距离。工作仔细、有毅力。对社会地位、社会评价比较在意，通常愿意在大型机构做一般性工作。",
                }
            ],

        }
    },
    mounted(){
        this.GetResultChartBoxXQ();// 兴趣分数图
    },
    methods:{

        // 初始化测评结果数据
        init(){
            let hollandAnswer = this.$ls.get("testAnswer");
            API.User.TestHollandInfo({ AnswerList: hollandAnswer }).then((res) => {
                this.hollandMainData = res.data;
                let temp = res.data.ResultScore;
                this.typeTestList = [];
                const typeArr = this.hollandMainData.ResultAnswers.join("").split("");
                typeArr.forEach((Item) => {
                    const index = this.typeTestList.findIndex(
                    (ite) => ite.order === Item
                    );
                    this.testResultTableData.forEach((TItem) => {
                    if (Item === TItem.order && index === -1) {
                        this.typeTestList.push(TItem);
                    }
                    });
                });
                this.testResultTableData.forEach((TItem) => {
                    for (let i in temp) {
                    if (i === TItem.order) {
                        TItem.score = temp[i];
                        TItem.percentage = temp[i] / 1.5 * 10;
                    }
                    }
                });
                // this.setEcharts();

                // loading.close()
                })
                .catch((err) => {
                // loading.close()
                });
        },
        
        // 报告下载（转换为PDF）
        resultdownloadbtn(){
            document.getElementById("result_download_loading").style.display = 'block';
            downloadPDF();
        },

        // 在线打印
        resultprintbtn(){
            document.getElementById("result_download_loading").style.display = 'block';
            let that = this;
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            html2canvas(this.$refs.user_result_pdf_box).then(function(canvas) {
                const image = canvas.toDataURL('image/jpeg', 1.0);
                setStore("TestRusultPrintImageUrl",image);
                document.getElementById("result_download_loading").style.display = 'none';
                window.open(that.$router.resolve({path: '/image'}).href, "_blank");
            });
        },

        // 兴趣分数图
        GetResultChartBoxXQ(){
            const chartDom = document.getElementById("ResultChartBoxXQ");
            const myChart = this.$echarts.init(chartDom);
            let option = {
                title: {
                    // text: 'Basic Radar Chart'
                },
                legend: {
                    // data: ['Actual Spending']
                },
                radar: {
                    indicator: [
                        { name: '现实型(R)', max: 7000, color:'#111'},
                        { name: '研究型(I)', max: 16000, color:'#111' },
                        { name: '艺术型(A)', max: 30000, color:'#111' },
                        { name: '社会型(S)', max: 38000, color:'#111' },
                        { name: '企业型(E)', max: 52000, color:'#111' },
                        { name: '常规型(C)', max: 25000, color:'#111' }
                    ]
                },
                series: [
                    {
                        // name: '兴趣雷达图',
                        type: 'radar',
                        color:'rgba('+getStore('ThemeColor')+',0.85)',
                        data: [
                            {
                                value: [5000, 14000, 28000, 26000, 42000, 21000],
                                // name: '兴趣雷达图'
                            }
                        ]
                    }
                ]
            }
            option && myChart.setOption(option);
        }
    }
}
</script>
<style lang="less" scoped>

.test_result_box{
    max-width: 1420px;
    margin: auto;
    .test_result_con{
        margin: 20px 20px 30px;
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 60px;
        padding: 0px 0 40px;
        box-shadow: 0 0 10px 1px #eee;
        .operate_box{
            width: 1000px;
            height: 70px;
            margin: 70px auto 0px;
            .btn_box{
                float: right;
                .btn{
                    padding: 10px 25px;
                    background-color: rgba(var(--themecolor),0.85);
                    border-radius: 50px;
                    color: #fff;
                    font-size: 16px;
                    float: left;
                    margin-left: 20px;
                    letter-spacing: 2px;
                    cursor: pointer;
                    transition: all 0.2s;
                }
                .btn:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .user_result_all{
            width: 1000px;
            margin: auto;
            overflow: hidden;
            box-shadow: 0 0 10px 1px rgba(var(--themecolor),0.3);
            background-color: #fff;
        }
        .user_result_box{
            width: 100%;
            position: relative;
            background-color: #fff;
            .radius1{
                position: absolute;
                width: 200px;
                height: 200px;
                border-radius: 100px;
                background-color: rgba(var(--themecolor),0.1);
                top: -110px;
                left: -110px;
                z-index: 0;
            }
            .user_box{
                width: 100%;
                height: 350px;
                background: url(../../../../src/assets/images/result-head.jpg) no-repeat center top / cover;
                .info{
                    margin-left: 80px;
                    margin-bottom: 25px;
                    .t{
                        font-size: 16px;
                        color: #444;
                    }
                    .x{
                        font-size: 24px;
                        color: #222;
                    }
                }
            }
            .title_box{
                color: #333;
                font-size: 40px;
                font-weight: bold;
                letter-spacing: 8px;
                margin: 60px 0 0px 0px;
                text-align: center;
            }
            .mark_box{
                margin: 60px 50px 20px;
                span{
                    font-size: 14px;
                    color: #333;
                    line-height: 26px;
                    display: block;
                }
            }
            .type_con{
                margin: 0px 50px;
                .type_result{
                    margin-top: 50px;
                    .tit_t{
                        color: #333;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .score_box{
                        margin-top: 30px;
                        .s_tit{
                            color: #333;
                            font-size: 18px;
                            width: 56px;
                        }
                        .score{
                            .s{
                                text-align: center;
                                width: calc(((100% - 56px) / 6) - 20px);
                                float: left;
                                border-radius: 5px;
                                margin: 0px 10px;
                                .z{
                                    display: block;
                                    font-size: 32px;
                                    font-weight: bold;
                                    color: #fff;
                                    margin-top: 5px;
                                }
                                .t{
                                    display: block;
                                    font-size: 13px;
                                    color: #fff;
                                    margin: 2px 0 5px;
                                }
                                .n{
                                    display: block;
                                    font-size: 32px;
                                    color: #fff;
                                    margin-bottom: 10px;
                                    .f{
                                        font-size: 16px;
                                        color: #fff;
                                        margin-left: 5px;
                                    }
                                }
                            }
                            .s.s_R{background-color: #3db7c2;}
                            .s.s_I{background-color: #6798e0;}
                            .s.s_A{background-color: #f07a62;}
                            .s.s_S{background-color: #ec9f3a;}
                            .s.s_E{background-color: #d3c63b;}
                            .s.s_C{background-color: #6cd44a;}
                        }
                        .s_mark{
                            color: #555;
                            font-size: 14px;
                            line-height: 24px;
                            margin: 15px 10px 0px 66px;
                        }
                        .df_box{
                            float: left;
                            width: calc(100% - 450px);
                            .xqlx{
                                width: 100%;
                                margin-top: 70px;
                                height: 50px;
                                line-height: 50px;
                                .tt{
                                    color: #333;
                                    font-size: 18px;
                                }
                                .jl_e{
                                    color: rgba(var(--themecolor),0.9);
                                    font-size: 30px;
                                    font-weight: bold;
                                }
                                .ch{
                                    font-size: 28px;
                                }
                                .jl_h{
                                    color: #333;
                                    font-size: 14px;
                                }
                                .jl_g{
                                    color: rgba(var(--themecolor),0.85);
                                    font-size: 18px;
                                }
                            }
                            .jlmark{
                                color: #333;
                                font-size: 16px;
                                line-height: 30px;
                                margin-top: 50px;
                            }
                        }
                        .ld_box{
                            width: 400px;
                            float: right;
                            .xqld{
                                margin-top: 30px;
                                text-align: center;
                                color: rgba(var(--themecolor),0.9);
                                font-size: 20px;
                                width: 100%;
                                text-align: center;
                                display: block;
                                height: 30px;
                            }
                            .cbox{
                                width: 400px;
                                height: 400px;
                            }
                        }
                        .type_table{
                            width: 100%;
                            border-top: 1px solid #bebdbd;
                            border-right: 1px solid #bebdbd;
                            margin-top: 10px;
                            .list{
                                width: 100%;
                                border-left: 1px solid #bebdbd;
                                border-bottom: 1px solid #bebdbd;
                                position: relative;
                                .bt{
                                    height: 40px;
                                    line-height: 40px;
                                    color: #333;
                                    font-size: 16px;
                                    font-weight: bold;
                                    text-align: center;
                                    float: left;
                                }
                                .bt1{
                                    width: 60px;
                                }
                                .bt2{
                                    width: 250px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .bt3{
                                    width: 150px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .bt4{
                                    width: calc(100% - 463px);
                                    border-left: 1px solid #bebdbd;
                                }
                                .head{
                                    width: 100px;
                                    display: inline-block;
                                    height: 100%;
                                    .ttbox{
                                        position: absolute;
                                        left: 15px;
                                        top: calc(50% - 35px);
                                        width: 70px;
                                        height: 70px;
                                        text-align: center;
                                        border-radius: 5px;
                                        .e{
                                            font-size: 30px;
                                            color: #fff;
                                            margin-top: 3px;
                                        }
                                        .z{
                                            font-size: 14px;
                                            color: #fff;
                                        }
                                        .y{
                                            color: rgba(var(--themecolor),0.85);
                                            font-size: 20px;
                                            font-weight: bold;
                                            line-height: 70px;
                                        }
                                        .j{
                                            font-size: 16px;
                                            color: rgba(var(--themecolor),0.85);
                                            font-weight: bold;
                                            margin-top: 13px;
                                            margin-bottom: 3px;
                                        }
                                        .s{
                                            font-size: 14px;
                                            color: #333;
                                            margin-top: 5px;
                                        }
                                    }
                                    .zyfx{
                                        height: 30px;
                                        line-height: 30px;
                                        top: calc(50% - 15px);
                                        .x{
                                            font-size: 16px;
                                            color: rgba(var(--themecolor),0.85);
                                            font-weight: bold;
                                        }
                                        .n{
                                            color: #333;
                                        }
                                        .p{
                                            font-size: 16px;
                                            color: #333;
                                        }
                                    }
                                }
                                .text{
                                    width: calc(100% - 102px);
                                    border-left: 1px solid #bebdbd;
                                    display: inline-block;
                                    .tz{
                                        border-bottom: 1px solid #bebdbd;
                                        position: relative;
                                        .t{
                                            width: 100px;
                                            display: inline-block;
                                            height: 100%;
                                            span{
                                                display: block;
                                                color: #333;
                                                font-size: 15px;
                                                height: 20px;
                                                line-height: 20px;
                                                position: absolute;
                                                left: 0px;
                                                top: calc(50% - 10px);
                                                text-align: right;
                                                width: 100px;
                                            }
                                        }
                                        .x{
                                            display: inline-block;
                                            border-left: 1px solid #bebdbd;
                                            padding: 10px 15px;
                                            line-height: 26px;
                                            color: #333;
                                            font-size: 15px;
                                        }
                                        .m{
                                            display: inline-block;
                                            padding: 15px 15px;
                                            line-height: 26px;
                                            color: #333;
                                            font-size: 15px;
                                        }
                                    }
                                    .tz:last-child{
                                        border-bottom:none;
                                    }
                                }
                            }
                            .order{
                                width: 100%;
                                border-left: 1px solid #bebdbd;
                                border-bottom: 1px solid #bebdbd;
                                position: relative;
                                .sj{
                                    height: 40px;
                                    line-height: 40px;
                                    color: #333;
                                    font-size: 15px;
                                    text-align: center;
                                    float: left;
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                    white-space:nowrap;
                                    position: relative;
                                    .n{
                                        color: #333;
                                        font-size: 13px;
                                        position: absolute;
                                        z-index: 1;
                                    }
                                    i{
                                        font-size: 27px;
                                        position: absolute;
                                        left: 20px;
                                        top: 0px;
                                        display: block;
                                        z-index: 0;
                                    }
                                }
                                .sj1{
                                    width: 60px;
                                }
                                .sj2{
                                    width: 250px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .sj3{
                                    width: 150px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .sj4{
                                    width: calc(100% - 463px);
                                    border-left: 1px solid #bebdbd;
                                }
                            }
                        }
                        .bt_tit{
                            color: #333;
                            font-size: 18px;
                            padding-top: 30px;
                        }
                        .tj_type{
                            width: 100%;
                            margin-top: 20px;
                            .tj_tit{
                                color: #333;
                                font-size: 16px;
                                height: 30px;
                                line-height: 30px;
                                position: relative;
                                text-indent: 12px;
                                .tj_ico{
                                    height: 16px;
                                    width: 5px;
                                    background-color: rgba(var(--themecolor),0.85);
                                    position: absolute;
                                    top: 7px;
                                    left: 0px;
                                }
                            }
                            .tj_box{
                                width: 100%;
                                margin-bottom: 30px;
                                .b{
                                    display: inline-block;
                                    background-color: rgba(var(--themecolor),0.15);
                                    color: #555;
                                    font-size: 14px;
                                    padding: 2px 12px;
                                    border-radius: 20px;
                                    margin: 7px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>