import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const downloadPDF = async => {

    window.pageYOffset = 0;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    let st = setTimeout(()=>{
        html2canvas(document.getElementById('user_result_pdf_box')).then(function(canvas) {
            canvas2PDF(canvas);
            clearTimeout(st);
        });
    },500);
};

const canvas2PDF = canvas => {

    const pdf = new jsPDF("p", "pt", 'a4'); //A4纸，纵向

    const contentWidth = canvas.width
    const contentHeight = canvas.height
    //一页pdf显示html页面生成的canvas高度;
    const pageHeight = (contentWidth / 595.28) * 841.89
    //未生成pdf的html页面高度
    let leftHeight = contentHeight
    //页面偏移
    let position = 0
    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    const imgWidth = 595.28
    const imgHeight = (592.28 / contentWidth) * contentHeight
    const pageData = canvas.toDataURL('image/jpeg', 1.0)

    if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        // 为下一条数据添加空白页
        pdf.addPage()
    } else {
        while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            //避免添加空白页
            if (leftHeight > 0) {
                pdf.addPage()
            }
        }
    }

    pdf.save("专业定位测评综合报告.pdf");
    
    document.getElementById("result_download_loading").style.display = 'none';
};
